import * as React from "react";
import { graphql } from "gatsby";

import Bio from "../components/bio";
import Layout from "../components/layout";
import Seo from "../components/seo";
import PostLists from "../components/PostList";

const Category = ({ pageContext, data, location }) => {
  const { category, categories } = pageContext;
  const totalCount = data.allMarkdownRemark.totalCount;
  const posts = data.allMarkdownRemark.nodes;
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const header = `「${category}」カテゴリの記事（${totalCount}件）`

  return (
    <Layout location={location} title={siteTitle} categories={categories}>
      <Seo title={header} />
      <Bio />
      <h4>{header}</h4>
      <PostLists posts={posts} />
    </Layout> 
  )
}

export default Category;

export const pageQuery = graphql`
  query($category: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: {category: {eq: $category } } }
    ) {
      totalCount
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          category
        }
      }
    }
  }
`